'use client'

import {
  BatchUserOperationCallData,
  deepHexlify,
  resolveProperties,
  SendUserOperationParameters,
  SmartContractAccount,
  UserOperationStruct_v6,
} from '@alchemy/aa-core'
import editSessionKey from '@lyra/core/api/private/editSessionKey'
import fetchSessionKey from '@lyra/core/api/private/fetchSessionKey'
import registerScopedSessionKey from '@lyra/core/api/private/registerScopedSessionKey'
import { PrivateRegisterScopedSessionKeyParamsSchema } from '@lyra/core/api/types/private.register_scoped_session_key'
import { MAX_INT } from '@lyra/core/constants/contracts'
import formatDateTime from '@lyra/core/utils/formatDateTime'
import formatTruncatedAddress from '@lyra/core/utils/formatTruncatedAddress'
import erc20Abi from '@lyra/web/abis/erc20Abi'
import {
  ClaimAirdropOptions,
  ClaimMigrationDrvOptions,
  DELAYED_AIRDROP_CLAIM_DATE_MS,
  DELAYED_CLAIM_BALANCE_THRESHOLD,
  PendingUnstake,
} from '@lyra/web/constants/drv'
import { SessionKeyScope } from '@lyra/web/constants/sessionKeys'
import useLocalSessionKey from '@lyra/web/hooks/useLocalSessionKey'
import useMigrationBalances from '@lyra/web/hooks/useMigrationBalances'
import useOrderbookTimestamp from '@lyra/web/hooks/useOrderbookTimestamp'
import {
  getCancelUnstakeTx,
  getClaimAirdropTx,
  getClaimRewardsTxs,
  getDelegateTx,
  getFinalizeUnstakeTx,
  getMigrationClaimFromScwTx,
  getStakeApprovalTx,
  getStakeTx,
  getUnstakePenaltyAmount,
  getUnstakeTx,
} from '@lyra/web/utils/drv'
import { getNetworkClient } from '@lyra/web/utils/rpc'
import { fetchBalancesApi } from '@lyra/web/utils/wallet'
import { mintOrRedeemYieldTokenImpl } from '@lyra/web/utils/yield'
import { getWalletClient } from '@wagmi/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Address, encodeFunctionData, Hash, isHex, PrivateKeyAccount, toHex } from 'viem'
import { generatePrivateKey, privateKeyToAccount } from 'viem/accounts'

import subaccountCreatorAbi from '../../abis/SubaccountCreator'
import { TransactionDisabledReason } from '../../constants/auth'
import { BridgeOptions, BridgeTransaction } from '../../constants/bridge'
import { DepositNetwork, lyraChain, mainnetChain } from '../../constants/chains'
import { lyraClient } from '../../constants/client'
import { lyraContractAddresses } from '../../constants/contracts'
import { isTestnet } from '../../constants/env'
import { DepositTokenId, TokenId } from '../../constants/tokens'
import {
  EnableTransactionOptions,
  EnableTransactionsResponse,
  TransactionOptions,
  TransactionStatusContext,
} from '../../constants/transactions'
import { HELP_BRIDGE_URL, HELP_ENABLE_DERIVE_URL } from '../../constants/urls'
import { User } from '../../constants/user'
import {
  DepositTokenBalances,
  EMPTY_DEPOSIT_TOKEN_BALANCES,
  getEmptyTokenBalances,
  LyraWalletClient,
} from '../../constants/wallet'
import {
  YieldTokenConfig,
  YieldTokenInputConfig,
  YieldTokenOutputConfig,
} from '../../constants/yield'
import useAuth from '../../hooks/useAuth'
import useEoaWallet from '../../hooks/useEoaWallet'
import {
  BridgeResponse,
  bridgeToLyraChain as bridgeToLyraChainImpl,
  fetchBridgeFromLyraChainLayerZeroTxs,
  fetchBridgeFromLyraChainTxs,
  getBridgeDurationEstimate,
  getBridgeType,
  getIsBridgeTxPending,
} from '../../utils/bridge'
import {
  formatDepositNetworkName,
  getChainForDepositNetwork,
  getDepositNetworkForChainId,
} from '../../utils/chains'
import {
  fetchNeedsDepositApproval,
  getApproveDepositAndWithdrawalTxs,
  updateUserPendingBridges,
} from '../../utils/client/deposits'
import {
  exportSessionKeySecret,
  generateSessionKeySecret,
  saveDeviceSessionKey,
  SESSION_KEY_EXPIRY_SECS,
  storeSessionPrivateKey,
} from '../../utils/client/deviceSessionKey'
import {
  getRegisterSessionKeyTx,
  getRevokeSessionKeyTx,
  waitForSessionKeyRegistration,
} from '../../utils/client/sessionKey'
import emptyFunction from '../../utils/emptyFunction'
import getAuthHeaders from '../../utils/getAuthHeaders'
import sleep from '../../utils/sleep'
import { waitForNewSubaccountId } from '../../utils/subaccounts'
import { getUtcSecs } from '../../utils/time'
import {
  formatDepositTokenBalance,
  formatTokenBalance,
  getCollateralAddress,
  getCollateralForToken,
  getLyraTokenAddress,
} from '../../utils/tokens'
import { fetchScwClient, getTransactionDisabledMessage, lyraEntrypoint } from '../../utils/wallet'
import { wagmiConfig } from '../WalletProvider'

// 5 minutes
const BALANCE_POLLING_INTERVAL_MS = 300_000

// 15 seconds
const PENDING_BRIDGES_POLLING_INTERVAL_MS = 10_000

type Props = {
  children?: React.ReactNode
}

export type TransactionContext = {
  address?: Address | undefined
  ownerAddress?: Address | undefined
  disabledReason?: TransactionDisabledReason | null | undefined
  canTransact?: boolean
  // TODO: @earthtojake remove sessionKey from TxProvider in favor of useLocalSessionKey hook
  sessionKey?: PrivateKeyAccount | null | undefined
  pendingBridges: BridgeTransaction[]
  balances: Record<TokenId, bigint>
  depositBalances: DepositTokenBalances
  // lyra protocol
  tryEnableTradingAccount: (
    options: EnableTransactionOptions
  ) => Promise<EnableTransactionsResponse>
  registerSessionKey: (
    sessionKeyAddress: Address,
    expiry: Date,
    label: string,
    scope: SessionKeyScope,
    options: TransactionOptions
  ) => Promise<void>
  revokeSessionKey: (sessionKeyAddress: Address, options: TransactionOptions) => Promise<void>
  editSessionKeyLabel: (sessionKeyAddress: Address, label: string) => Promise<void>
  // bridging
  bridgeToLyraChain: (
    network: DepositNetwork,
    token: DepositTokenId,
    amount: bigint,
    bridgeOptions: BridgeOptions,
    options: TransactionOptions
  ) => Promise<BridgeResponse>
  bridgeFromLyraChain: (
    receiver: Address,
    withdrawNetwork: DepositNetwork,
    token: TokenId,
    withdrawToken: DepositTokenId,
    amount: bigint,
    options: TransactionOptions
  ) => Promise<Hash>
  // yield
  mintYieldToken: (
    amount: bigint,
    config: YieldTokenConfig,
    inputConfig: YieldTokenInputConfig,
    outputConfig: YieldTokenOutputConfig,
    options: TransactionOptions
  ) => Promise<Hash>
  redeemYieldToken: (
    amount: bigint,
    config: YieldTokenConfig,
    inputConfig: YieldTokenInputConfig,
    outputConfig: YieldTokenOutputConfig,
    options: TransactionOptions
  ) => Promise<Hash>
  // testnet
  mintTestnetUsdc: () => Promise<bigint>
  // mutations
  mutateBalances: () => Promise<{
    balances: Record<TokenId, bigint>
    depositBalances: DepositTokenBalances
  }>
  mutateAndUpdatePendingBridges: () => Promise<BridgeTransaction[]>
  createAndDepositFirstSubaccount: (options: EnableTransactionOptions) => Promise<{
    txHash: Hash
    sessionKey: PrivateKeyAccount
    subaccountId: number | undefined
    user: User
  }>
  stake: (amount: bigint, options: TransactionOptions) => Promise<Hash>
  unstake: (amount: bigint, isInstant: boolean, options: TransactionOptions) => Promise<Hash>
  cancelUnstake: (pendingUnstake: PendingUnstake, options: TransactionOptions) => Promise<Hash>
  finalizeUnstake: (pendingUnstake: PendingUnstake, options: TransactionOptions) => Promise<Hash>
  claimAirdrop: (claimOptions: ClaimAirdropOptions, options: TransactionOptions) => Promise<Hash>
  claimMigration: (
    claimOptions: ClaimMigrationDrvOptions,
    options: TransactionOptions
  ) => Promise<Hash>
  claimRewards: (batchIds: string[], options: TransactionOptions) => Promise<Hash>
}

export const TransactionContext = React.createContext<TransactionContext>({
  pendingBridges: [],
  balances: getEmptyTokenBalances(),
  depositBalances: EMPTY_DEPOSIT_TOKEN_BALANCES,
  tryEnableTradingAccount: emptyFunction as any,
  registerSessionKey: emptyFunction as any,
  revokeSessionKey: emptyFunction as any,
  editSessionKeyLabel: emptyFunction as any,
  bridgeToLyraChain: emptyFunction as any,
  bridgeFromLyraChain: emptyFunction as any,
  mintYieldToken: emptyFunction as any,
  redeemYieldToken: emptyFunction as any,
  mintTestnetUsdc: emptyFunction as any,
  mutateBalances: emptyFunction as any,
  mutateAndUpdatePendingBridges: emptyFunction as any,
  createAndDepositFirstSubaccount: emptyFunction as any,
  stake: emptyFunction as any,
  unstake: emptyFunction as any,
  cancelUnstake: emptyFunction as any,
  finalizeUnstake: emptyFunction as any,
  claimAirdrop: emptyFunction as any,
  claimMigration: emptyFunction as any,
  claimRewards: emptyFunction as any,
})

export default function TransactionProvider({ children }: Props) {
  const { user, isAuthenticated, accountDisabledReason, mutate: mutateAuth } = useAuth()
  const { getTimestamp } = useOrderbookTimestamp()

  const { data: sessionKey, mutate: setSessionKey } = useLocalSessionKey()

  // External wallet
  const { walletClient: externalWalletClient, isConnected: externalIsConnected } = useEoaWallet()

  const disabledReason: TransactionDisabledReason | undefined = accountDisabledReason
    ? accountDisabledReason
    : isAuthenticated && !externalIsConnected
    ? 'eoa-reconnect'
    : undefined

  // Balances
  // TODO @michaelxuwu move these out of TransactionProvider into hooks
  const [balances, setBalances] = useState<Record<TokenId, bigint>>(getEmptyTokenBalances())
  const [depositBalances, setDepositBalances] = useState<DepositTokenBalances>(
    EMPTY_DEPOSIT_TOKEN_BALANCES
  )
  const { claimableDrvBalance: migrationDrvBalance, claimableStDrvBalance: migrationStDrvBalance } =
    useMigrationBalances()

  // Pending deposits
  const [pendingBridges, setPendingBridges] = useState<BridgeTransaction[]>([])

  const walletClient: LyraWalletClient | undefined = externalWalletClient

  const address = user?.address
  const ownerAddress = user?.ownerAddress

  // TODO: @earthtojake move into swr hooks in relevant components
  const mutateBalances = useCallback(async () => {
    if (!isAuthenticated) {
      setBalances(getEmptyTokenBalances())
      setDepositBalances(EMPTY_DEPOSIT_TOKEN_BALANCES)
      return {
        balances: getEmptyTokenBalances(),
        depositBalances: EMPTY_DEPOSIT_TOKEN_BALANCES,
      }
    } else {
      // Note: to always show balances, we route query via api
      const { balances, depositBalances } = await fetchBalancesApi()
      setBalances(balances)
      setDepositBalances(depositBalances)
      console.debug('mutateBalances', { balances, depositBalances })
      return { balances, depositBalances }
    }
    // IMPORTANT: should only update when user authenticates
  }, [isAuthenticated])

  const mutateAndUpdatePendingBridges = useCallback(async () => {
    if (!isAuthenticated) {
      setPendingBridges([])
      return []
    } else {
      // Update pending bridges
      const updatedBridges = await updateUserPendingBridges()
      const pendingBridges = updatedBridges.filter((b) => getIsBridgeTxPending(b).isPending)
      console.debug('mutatePendingBridges', pendingBridges)
      setPendingBridges(pendingBridges)
      return pendingBridges
    }
  }, [isAuthenticated])

  /**
   * INTERNAL
   */

  /**
   * Send batched transactions
   * @notice make sure the sending wallet has at least 1 USD if the tx is not sponsored
   **/
  const _sendUserOperation = useCallback(
    async (
      walletClient: LyraWalletClient,
      sendParams: SendUserOperationParameters<SmartContractAccount>,
      {
        onTransactionStatusChange,
        skipCompleteStatus,
        confirmContext,
        inProgressContext,
        completeContext,
      }: TransactionOptions & {
        confirmContext?: TransactionStatusContext
        inProgressContext?: TransactionStatusContext
        completeContext?: TransactionStatusContext
      }
    ): Promise<Hash> => {
      if (!walletClient) {
        throw new Error('Wallet not ready')
      }

      const uoRequests = Array.isArray(sendParams.uo) ? sendParams.uo : [sendParams.uo]

      const batch: BatchUserOperationCallData = uoRequests.map((request) => {
        if (isHex(request)) {
          throw new Error('Direct Hex Operations not supported')
        }
        if (!request.target) {
          throw new Error('Missing target address')
        }
        return {
          target: request.target,
          data: request.data ?? '0x',
          value: request.value ?? BigInt(0),
        }
      })

      console.debug('_sendUserOperation', uoRequests)

      // hitting paymaster to generate message for user to sign
      onTransactionStatusChange('paymaster', { ...confirmContext })

      const client = await fetchScwClient(walletClient)

      const overriddenSendParams: SendUserOperationParameters<SmartContractAccount> = {
        ...sendParams,
        uo: batch,
      }

      const uoStruct = (await client.buildUserOperation(
        overriddenSendParams
      )) as UserOperationStruct_v6
      const resolvedStruct = await resolveProperties<UserOperationStruct_v6>(uoStruct)
      const requestToSign = deepHexlify(resolvedStruct)

      const hashToSign = client.account
        .getEntryPoint()
        .getUserOperationHash(deepHexlify(resolvedStruct))

      onTransactionStatusChange('confirm', {
        ...confirmContext,
        uoHash: hashToSign,
        uo: requestToSign,
      })

      // popup sign in wallet
      const request = await client.signUserOperation({ uoStruct })

      const uoHash = await client.sendRawUserOperation(request, lyraEntrypoint.address)

      // user has signed
      onTransactionStatusChange('in-progress', inProgressContext)

      const transactionHash = await client.waitForUserOperationTransaction({ hash: uoHash })
      console.debug('UO included in tx:', transactionHash)

      const receipt = await client.getUserOperationReceipt(uoHash)

      if (receipt === null || receipt.success === false) {
        throw new Error(`User operation failed: ${transactionHash}`)
      }

      if (!skipCompleteStatus) {
        onTransactionStatusChange('complete', {
          ...completeContext,
          txHash: transactionHash,
          chain: lyraChain,
        })
      }

      return transactionHash
    },
    []
  )

  /**
   * Switch wallet client to supported or specified network
   */

  const _switchNetwork = useCallback(
    async (
      networkish: DepositNetwork | 'any',
      options: EnableTransactionOptions
    ): Promise<LyraWalletClient> => {
      if (!walletClient) {
        console.log('_switchNetwork', { walletClient })
        throw new Error('Wallet not ready')
      }

      const chainId = await walletClient.getChainId()

      const switchToChain =
        networkish === 'any'
          ? !getDepositNetworkForChainId(chainId)
            ? mainnetChain
            : undefined
          : getChainForDepositNetwork(networkish).id !== chainId
          ? getChainForDepositNetwork(networkish)
          : undefined

      if (switchToChain) {
        console.debug('switching from chain id', chainId)

        options.onTransactionStatusChange('switch-network', {
          chain: switchToChain,
          title: `Switch to ${switchToChain.name}`,
        })

        await walletClient.switchChain({ id: switchToChain.id })

        // Dev note: account for delay between chain switching and walletClient object updating its state
        let retry = 100
        let newWalletClient: LyraWalletClient = walletClient
        while (
          !newWalletClient.chain ||
          (newWalletClient.chain.id !== switchToChain.id && retry > 0)
        ) {
          retry--
          newWalletClient = await getWalletClient(wagmiConfig)
          await sleep(500)
        }

        console.debug('switched to chain id', newWalletClient.chain.id)

        return newWalletClient
      } else {
        return walletClient
      }
    },
    [walletClient]
  )

  /**
   * Initializes an account:
   * - Creates subaccount
   * - Registers auth session key
   * - Registers device session key
   * - Approves set of collateral assets
   */
  const createAndDepositFirstSubaccount = useCallback(
    async (
      options: EnableTransactionOptions
    ): Promise<{
      txHash: Hash
      sessionKey: PrivateKeyAccount
      subaccountId: number | undefined
      user: User
    }> => {
      if (!address) {
        throw new Error('Wallet not ready')
      }

      if (!user) {
        throw new Error('Not authenticated')
      }

      // switch network
      const walletClient = await _switchNetwork('any', options)

      const txs: BatchUserOperationCallData = []

      // #1: Register device session key
      const sessionKeyExpiry = new Date()
      sessionKeyExpiry.setTime(sessionKeyExpiry.getTime() + SESSION_KEY_EXPIRY_SECS * 1000)
      const sessionPrivateKey = generatePrivateKey()
      const newSessionKey = privateKeyToAccount(sessionPrivateKey)

      console.debug('register session key:', newSessionKey.address, sessionKeyExpiry.getTime())
      txs.push(getRegisterSessionKeyTx(newSessionKey.address, sessionKeyExpiry))

      // #2.x: Approve contracts
      const approveTxs = getApproveDepositAndWithdrawalTxs()
      for (const approveTx of approveTxs) {
        txs.push(approveTx)
      }

      const sessionKeySecret = await generateSessionKeySecret()
      const exportedSessionKeySecret = await exportSessionKeySecret(sessionKeySecret)

      // #4: create subaccount
      const shouldCreateSubaccount = options.createSubaccount ?? true
      if (shouldCreateSubaccount) {
        const firstSubaccountParams = options?.firstSubaccountParams
        const token = firstSubaccountParams?.token ?? TokenId.USDC
        const collateral = getCollateralForToken(token)
        const collateralAddress = getCollateralAddress(collateral)
        const amount = firstSubaccountParams?.amount ?? BigInt(0)

        // initial amount
        const createSubaccountTxArgs: [`0x${string}`, bigint, `0x${string}`] = [
          collateralAddress,
          amount,
          // standard manager
          lyraContractAddresses.standardManager,
        ]

        // Approve subaccount creator
        console.debug('approving subaccountCreator')
        txs.push({
          target: getLyraTokenAddress(token),
          data: encodeFunctionData({
            abi: erc20Abi,
            functionName: 'approve',
            args: [lyraContractAddresses.subaccountCreator, MAX_INT],
          }),
        })

        txs.push({
          target: lyraContractAddresses.subaccountCreator,
          data: encodeFunctionData({
            abi: subaccountCreatorAbi,
            functionName: 'createAndDepositSubAccount',
            args: createSubaccountTxArgs,
          }),
        })
      }
      // Note: skip complete status to allow session keys and subaccount to sync with orderbook
      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          skipCompleteStatus: true,
          confirmContext: {
            title: 'Create Trading Account',
            contextLink: {
              href: HELP_ENABLE_DERIVE_URL,
              label: 'Why is this required?',
              target: '_blank',
            },
          },
          inProgressContext: {
            title: 'Creating Trading Account',
          },
        }
      )

      // register with db
      const authHeaders = getAuthHeaders(user)

      const [isSessionKeyRegistered, newSubaccountId] = await Promise.all([
        waitForSessionKeyRegistration(address, newSessionKey.address, authHeaders),
        shouldCreateSubaccount ? waitForNewSubaccountId(user.address, [], authHeaders) : null,
      ])

      if (!isSessionKeyRegistered) {
        throw new Error('Session key not registered with orderbook')
      }

      const isSessionKeySaved = await saveDeviceSessionKey(
        newSessionKey.address,
        exportedSessionKeySecret
      )
      if (!isSessionKeySaved) {
        throw new Error('Register session key failed')
      }

      // store encrypted private key in local storage
      await storeSessionPrivateKey(sessionPrivateKey, sessionKeySecret)

      const [newAuth] = await Promise.all([mutateAuth(), mutateBalances()])

      if (!newAuth?.user) {
        throw new Error('initAccount failed')
      }

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: 'Created Trading Account',
        })
      }

      setSessionKey(newSessionKey, { revalidate: false })

      return {
        txHash,
        sessionKey: newSessionKey,
        subaccountId: newSubaccountId ?? undefined,
        user: newAuth.user,
      }
    },
    [address, user, _switchNetwork, _sendUserOperation, mutateAuth, mutateBalances, setSessionKey]
  )

  /**
   * Register device session key for user
   * Required when user signs in on new device after activating trading
   */
  const _registerDeviceSessionKey = useCallback(
    async (
      walletClient: LyraWalletClient,
      withApproveDeposit: boolean,
      options: TransactionOptions
    ) => {
      if (!address) {
        throw new Error('Wallet not ready')
      }
      if (!user) {
        throw new Error('Not authenticated')
      }

      const sessionKeyExpiry = new Date()
      sessionKeyExpiry.setTime(sessionKeyExpiry.getTime() + SESSION_KEY_EXPIRY_SECS * 1000)
      const sessionPrivateKey = generatePrivateKey()
      const sessionKey = privateKeyToAccount(sessionPrivateKey)

      const txs = [getRegisterSessionKeyTx(sessionKey.address, sessionKeyExpiry)]

      if (withApproveDeposit) {
        const approvalTxs = getApproveDepositAndWithdrawalTxs()
        for (const approvalTx of approvalTxs) {
          txs.push(approvalTx)
        }
      }

      // Note: skip complete status to allow session key to sync with orderbook
      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          skipCompleteStatus: true,
          confirmContext: {
            title: 'Enable Derive on device',
            context: 'Create a session key on this device to use Derive.',
            contextLink: {
              href: HELP_ENABLE_DERIVE_URL,
              label: 'Why is this required?',
              target: '_blank',
            },
          },
          inProgressContext: {
            title: 'Enabling Derive',
          },
        }
      )

      const authHeaders = getAuthHeaders(user)
      const isRegistered = await waitForSessionKeyRegistration(
        address,
        sessionKey.address,
        authHeaders
      )
      if (!isRegistered) {
        throw new Error('Session key not registered with orderbook')
      }

      const sessionKeySecret = await generateSessionKeySecret()

      const exportedSessionKeySecret = await exportSessionKeySecret(sessionKeySecret)
      const isSessionKeySaved = await saveDeviceSessionKey(
        sessionKey.address,
        exportedSessionKeySecret
      )
      if (!isSessionKeySaved) {
        throw new Error('Request failed')
      }
      // store encrypted private key in local storage
      await storeSessionPrivateKey(sessionPrivateKey, sessionKeySecret)
      await mutateAuth()

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: 'Enabled Derive',
        })
      }

      setSessionKey(sessionKey, { revalidate: false })

      return { txHash, sessionKey }
    },
    [address, user, _sendUserOperation, mutateAuth, setSessionKey]
  )

  /**
   * Approves Lyra deposits:
   * - deposit module for deposits
   * - paymaster for self-paying transactions
   */
  const _approveDepositsAndPaymaster = useCallback(
    async (walletClient: LyraWalletClient, options: TransactionOptions) => {
      const txs = getApproveDepositAndWithdrawalTxs()
      await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: 'Enable Derive on device',
            context: 'Create a session key on this device to use Derive.',
            contextLink: {
              href: HELP_ENABLE_DERIVE_URL,
              label: 'Why is this required?',
              target: '_blank',
            },
          },
          inProgressContext: {
            title: 'Enabling Derive',
          },
          completeContext: {
            title: 'Enabled Derive',
          },
        }
      )
    },
    [_sendUserOperation]
  )

  /**
   * !! SUPER IMPORTANT
   *
   * _checkScw MUST be called before every transaction and enforces:
   * - kyt checks
   * - geoblocking
   * - invalid wallet configurations (e.g. wrong scw address)
   */

  const _checkScw = useCallback(async () => {
    if (!user) {
      throw new Error('Not authenticated')
    }

    if (!address || !ownerAddress) {
      console.debug('checkWallet address', { address, ownerAddress })
      throw new Error('Wallet not ready')
    }

    if (disabledReason) {
      console.debug('checkWallet disabled', disabledReason)
      throw new Error(getTransactionDisabledMessage(disabledReason))
    }

    const [bytecodesRes, rpcRes] = await Promise.allSettled([
      Promise.all(
        Object.values(DepositNetwork).map(async (network) => {
          const client = await getNetworkClient(network)
          return await client.getBytecode({
            address: ownerAddress,
          })
        })
      ),
      lyraClient.request({ method: 'eth_chainId' }),
    ])

    if (rpcRes.status === 'rejected') {
      throw new Error('Failed to connect to Derive RPC')
    }

    if (bytecodesRes.status === 'rejected') {
      throw new Error('Failed to fetch bytecodes')
    }

    // block smart contract wallets
    if (bytecodesRes.value.some((code) => !!code)) {
      throw new Error('Smart contract wallets are not supported')
    }

    return {
      address,
      ownerAddress,
      user,
    }
  }, [disabledReason, user, address, ownerAddress])

  /**
   * TRANSACTION FUNCTIONS
   */

  /**
   * tryEnableTradingAccount does the following:
   * - registers device session key (if device has no session key)
   * - approves deposit module and paymaster for all collateral (if allowance below threshold)
   *
   * Required for the following transactions:
   * - submit orders
   * - deposit into subaccount
   * - withdraw from subaccount
   * - transfer between subaccounts
   */
  const tryEnableTradingAccount = useCallback(
    async (options: EnableTransactionOptions): Promise<EnableTransactionsResponse> => {
      const { user, address } = await _checkScw()

      if (!user) {
        throw new Error('User has no trading accounts')
      }

      let thisSessionKey: PrivateKeyAccount
      let didExecuteTx = false

      const needsDepositApproval = await fetchNeedsDepositApproval(address)
      if (!sessionKey) {
        // switch network
        const walletClient = await _switchNetwork('any', options)
        // register session key, optionally approve deposits if this is not set
        const { sessionKey: newSessionKey } = await _registerDeviceSessionKey(
          walletClient,
          needsDepositApproval,
          options
        )
        thisSessionKey = newSessionKey
        didExecuteTx = true
      } else {
        if (needsDepositApproval) {
          // switch network
          const walletClient = await _switchNetwork('any', options)
          // approve deposits
          await _approveDepositsAndPaymaster(walletClient, options)
          didExecuteTx = true
        }
        thisSessionKey = sessionKey
      }

      if (!didExecuteTx && !options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete')
      }

      return {
        sessionKey: thisSessionKey,
        user,
      }
    },
    [_checkScw, _switchNetwork, sessionKey, _registerDeviceSessionKey, _approveDepositsAndPaymaster]
  )

  /**
   * Register developer session key for account
   */
  const registerSessionKey = useCallback(
    async (
      sessionKeyAddress: Address,
      expiry: Date,
      label: string,
      scope: SessionKeyScope,
      options: TransactionOptions
    ) => {
      const { user } = await _checkScw()

      if (!user) {
        throw new Error('Subaccount not created')
      }
      if (!address) {
        throw new Error('Invalid owner address')
      }

      const authHeaders = getAuthHeaders(user)

      if (scope === 'admin') {
        const txs = [getRegisterSessionKeyTx(sessionKeyAddress, expiry)]
        const walletClient = await _switchNetwork('any', options)

        const txHash = await _sendUserOperation(
          walletClient,
          { uo: txs },
          {
            ...options,
            skipCompleteStatus: true,
            confirmContext: {
              title: 'Confirm Register Session Key',
            },
            inProgressContext: {
              title: 'Registering Session Key',
            },
          }
        )

        const authHeaders = getAuthHeaders(user)

        const isRegistered = await waitForSessionKeyRegistration(
          address,
          sessionKeyAddress,
          authHeaders
        )
        if (!isRegistered) {
          throw new Error('Failed to register session key onchain')
        }

        await editSessionKey(
          {
            wallet: user.address,
            public_session_key: sessionKeyAddress,
            label,
          },
          authHeaders
        )

        if (!options.skipCompleteStatus) {
          options.onTransactionStatusChange('complete', {
            txHash,
            chain: lyraChain,
            title: 'Successfully Registered Session Key',
          })
        }
      } else {
        options.onTransactionStatusChange('in-progress', { title: 'Registering Session Key' })

        const params: PrivateRegisterScopedSessionKeyParamsSchema = {
          public_session_key: sessionKeyAddress,
          expiry_sec: getUtcSecs(expiry),
          scope,
          wallet: address,
        }

        console.debug('registerScopedSessionKey', params)

        await registerScopedSessionKey(params, authHeaders)

        // note: setting label in register doesn't seem to work, so set it after registration
        const editParams = {
          wallet: user.address,
          public_session_key: sessionKeyAddress,
          label,
        }

        console.debug('editSessionKey', params)

        await editSessionKey(editParams, authHeaders)

        if (!options.skipCompleteStatus) {
          options.onTransactionStatusChange('complete', {
            title: 'Successfully Registered Session Key',
          })
        }
      }
    },
    [_checkScw, address, _switchNetwork, _sendUserOperation]
  )

  /**
   * Revoke session key (developer or device) for account
   */
  const revokeSessionKey = useCallback(
    async (sessionKeyAddress: Address, options: TransactionOptions) => {
      const { user } = await _checkScw()

      if (!user) {
        throw new Error('Subaccount not created')
      }

      const authHeaders = getAuthHeaders(user)

      const sessionKey = await fetchSessionKey(
        { wallet: user.address, sessionKey: sessionKeyAddress },
        authHeaders
      )

      if (!sessionKey) {
        throw new Error('Session key does not exist for wallet')
      }

      if (sessionKey.scope === 'admin') {
        const walletClient = await _switchNetwork('any', options)

        // Note: skip complete status to allow session key to sync with orderbook
        const txHash = await _sendUserOperation(
          walletClient,
          { uo: getRevokeSessionKeyTx(sessionKeyAddress) },
          {
            ...options,
            skipCompleteStatus: true,
            confirmContext: {
              title: 'Confirm Revoke Session Key',
            },
            inProgressContext: {
              title: 'Revoking Session Key',
            },
          }
        )

        if (!options.skipCompleteStatus) {
          options.onTransactionStatusChange('complete', {
            txHash,
            chain: lyraChain,
            title: 'Successfully Revoked Session Key',
          })
        }
      } else {
        await editSessionKey(
          {
            wallet: user.address,
            public_session_key: sessionKeyAddress,
            disable: true,
          },
          authHeaders
        )

        if (!options.skipCompleteStatus) {
          options.onTransactionStatusChange('complete', {
            title: 'Successfully Registered Session Key',
          })
        }
      }
    },
    [_checkScw, _switchNetwork, _sendUserOperation]
  )

  /**
   * Edit session key label
   * Note: This is not a transaction
   */

  const editSessionKeyLabel = useCallback(
    async (sessionKeyAddress: Address, label: string) => {
      if (!user || !user) {
        throw new Error('Subaccount not created')
      }

      const authHeaders = getAuthHeaders(user)

      await editSessionKey(
        {
          wallet: user.address,
          public_session_key: sessionKeyAddress,
          label,
        },
        authHeaders
      )
    },
    [user]
  )

  /**
   * Bridges token to Lyra Chain and queues subaccount deposit
   * Note: This function assumes an account has been initialized
   */
  const bridgeToLyraChain = useCallback(
    async (
      network: DepositNetwork,
      token: DepositTokenId,
      amount: bigint,
      bridgeOptions: BridgeOptions,
      options: TransactionOptions
    ) => {
      const { address } = await _checkScw()

      const walletClient = await _switchNetwork(network, options)

      const response = await bridgeToLyraChainImpl(
        walletClient,
        address,
        network,
        token,
        amount,
        bridgeOptions,
        options
      )

      await Promise.all([mutateAuth(), mutateBalances(), mutateAndUpdatePendingBridges()])

      return response
    },
    [_checkScw, _switchNetwork, mutateAuth, mutateBalances, mutateAndUpdatePendingBridges]
  )

  const bridgeFromLyraChain = useCallback(
    async (
      receiver: Address,
      withdrawNetwork: DepositNetwork,
      token: TokenId,
      withdrawToken: DepositTokenId,
      amount: bigint,
      options: TransactionOptions
    ) => {
      const { ownerAddress, address } = await _checkScw()

      // switch to withdraw network
      const walletClient = await _switchNetwork(withdrawNetwork, options)

      // check if user needs to approve paymaster
      // edge case if user immediately deposits and then withdrawals
      const needsWithdrawApproval = await fetchNeedsDepositApproval(address)

      if (needsWithdrawApproval) {
        // approve deposits
        await _approveDepositsAndPaymaster(walletClient, { ...options, skipCompleteStatus: true })
      }

      let txs: BatchUserOperationCallData = []
      const bridgeType = getBridgeType(token)
      if (bridgeType === 'layer-zero') {
        txs = await fetchBridgeFromLyraChainLayerZeroTxs(receiver, withdrawNetwork, token, amount)
      } else {
        // !!SUPER IMPORTANT!!
        // addres is always smart contract wallet address
        // receiver is defined (typically owner address)
        // FAILURE TO SET THE RECEIVER ADDRESS CORRECTLY WILL RESULT IN LOSS OF FUNDS
        txs = await fetchBridgeFromLyraChainTxs({
          receiver,
          withdrawNetwork,
          token,
          withdrawToken,
          amount,
        })
      }

      // Use flat fee for bundler
      // Note: skip complete status to allow registering withdraw with backend
      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          skipCompleteStatus: true,
          confirmContext: {
            title: `Withdraw ${formatDepositTokenBalance(
              amount,
              withdrawToken
            )} to ${formatDepositNetworkName(withdrawNetwork)}`,
            context: `Withdrawals typically take ${getBridgeDurationEstimate(withdrawNetwork)}.`,
            contextLearnMoreHref: HELP_BRIDGE_URL,
          },
          inProgressContext: {
            title: `Withdrawing ${formatDepositTokenBalance(
              amount,
              withdrawToken
            )} to ${formatDepositNetworkName(withdrawNetwork)}`,
          },
        }
      )

      const res = await fetch('/api/withdraw', {
        method: 'POST',
        body: JSON.stringify({
          amount: toHex(amount),
          network: withdrawNetwork,
          token: withdrawToken,
          txHash,
          bridgeType,
        }),
      })

      if (!res.ok) {
        throw new Error(
          'Withdraw was successful but Derive failed to log your transaction. Please contact support.'
        )
      }

      await lyraClient.waitForTransactionReceipt({ hash: txHash })
      await Promise.all([mutateAuth(), mutateBalances(), mutateAndUpdatePendingBridges()])

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: `Successfully Withdrew ${formatDepositTokenBalance(amount, withdrawToken)}`,
          context: `Your funds will be available in your connected wallet (${formatTruncatedAddress(
            ownerAddress
          )}) on ${formatDepositNetworkName(withdrawNetwork)} in ${getBridgeDurationEstimate(
            withdrawNetwork
          )}.`,
        })
      }

      return txHash
    },
    [
      _checkScw,
      _switchNetwork,
      _sendUserOperation,
      mutateAuth,
      mutateBalances,
      mutateAndUpdatePendingBridges,
      _approveDepositsAndPaymaster,
    ]
  )

  const mintYieldToken = useCallback(
    async (
      amount: bigint,
      config: YieldTokenConfig,
      inputConfig: YieldTokenInputConfig,
      outputConfig: YieldTokenOutputConfig,
      options: TransactionOptions
    ) => {
      _checkScw()

      // switch networks
      const walletClient = await _switchNetwork(inputConfig.network, options)

      return await mintOrRedeemYieldTokenImpl(
        walletClient,
        amount,
        true /* isMint */,
        config,
        inputConfig,
        outputConfig,
        options
      )
    },
    [_checkScw, _switchNetwork]
  )

  const redeemYieldToken = useCallback(
    async (
      amount: bigint,
      config: YieldTokenConfig,
      inputConfig: YieldTokenInputConfig,
      outputConfig: YieldTokenOutputConfig,
      options: TransactionOptions
    ) => {
      _checkScw()

      // switch networks
      const walletClient = await _switchNetwork(inputConfig.network, options)

      return await mintOrRedeemYieldTokenImpl(
        walletClient,
        amount,
        false /* isMint */,
        config,
        inputConfig,
        outputConfig,
        options
      )
    },
    [_checkScw, _switchNetwork]
  )

  /**
   * DRV
   */
  const unstake = useCallback(
    async (amount: bigint, isInstant: boolean, options: TransactionOptions) => {
      if (!walletClient) {
        throw new Error('Wallet not ready')
      }

      const txs = [getUnstakeTx(amount, { isInstant })]

      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: 'Confirm Unstake',
          },
          inProgressContext: {
            title: isInstant
              ? `Unstaking ${formatTokenBalance(amount, TokenId.STDRV)}`
              : `Queuing Unstake ${formatTokenBalance(amount, TokenId.STDRV)}`,
          },
        }
      )

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: isInstant
            ? `Successfully Unstaked ${formatTokenBalance(amount, TokenId.STDRV)}`
            : `Successfully Queued Unstake ${formatTokenBalance(amount, TokenId.STDRV)}`,
        })
      }
      return txHash
    },
    [_sendUserOperation, walletClient]
  )

  const cancelUnstake = useCallback(
    async (pendingUnstake: PendingUnstake, options: TransactionOptions) => {
      if (!walletClient) {
        throw new Error('Wallet not ready')
      }
      const txs = [getCancelUnstakeTx(pendingUnstake.redeemIndex)]

      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: `Confirm Cancel Unstake ${formatTokenBalance(
              pendingUnstake.stDrvAmount,
              TokenId.STDRV
            )}`,
          },
          inProgressContext: {
            title: `Cancelling Unstake ${formatTokenBalance(
              pendingUnstake.stDrvAmount,
              TokenId.STDRV
            )}`,
          },
        }
      )

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: `Successfully Cancelled Unstake ${formatTokenBalance(
            pendingUnstake.stDrvAmount,
            TokenId.STDRV
          )}`,
        })
      }
      return txHash
    },
    [_sendUserOperation, walletClient]
  )

  const finalizeUnstake = useCallback(
    async (pendingUnstake: PendingUnstake, options: TransactionOptions) => {
      if (!walletClient) {
        throw new Error('Wallet not ready')
      }
      const txs = [getFinalizeUnstakeTx(pendingUnstake.redeemIndex)]

      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: `Complete Unstake ${formatTokenBalance(
              pendingUnstake.stDrvAmount,
              TokenId.STDRV
            )}`,
          },
          inProgressContext: {
            title: `Completing Unstake ${formatTokenBalance(
              pendingUnstake.stDrvAmount,
              TokenId.STDRV
            )}`,
          },
        }
      )

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: `Completed Unstake ${formatTokenBalance(
            pendingUnstake.stDrvAmount,
            TokenId.STDRV
          )}`,
        })
      }
      return txHash
    },
    [_sendUserOperation, walletClient]
  )

  const stake = useCallback(
    async (amount: bigint, options: TransactionOptions) => {
      if (!walletClient || !user) {
        throw new Error('Wallet not ready')
      }

      const txs = []

      // APPROVAL?
      const allowance = await lyraClient.readContract({
        abi: erc20Abi,
        address: getLyraTokenAddress(TokenId.DRV),
        functionName: 'allowance',
        args: [user.address, getLyraTokenAddress(TokenId.STDRV)],
      })

      if (allowance < amount) {
        txs.push(getStakeApprovalTx())
      }

      txs.push(getStakeTx(amount))

      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: 'Confirm Stake',
          },
          inProgressContext: {
            title: `Staking ${formatTokenBalance(amount, TokenId.DRV)}`,
          },
        }
      )

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: `Successfully Staked ${formatTokenBalance(amount, TokenId.DRV)}`,
        })
      }
      return txHash
    },
    [_sendUserOperation, user, walletClient]
  )

  const claimMigration = useCallback(
    async (
      { amount, isStake, delegates }: ClaimMigrationDrvOptions,
      options: TransactionOptions
    ) => {
      if (!walletClient || !user) {
        throw new Error('Wallet not ready')
      }

      if (!user.acknowledgedAirdropTerms) {
        await fetch('/api/acknowledge-terms/airdrop', { method: 'POST' })

        const newAuth = await mutateAuth()
        if (!newAuth?.user?.acknowledgedAirdropTerms) {
          throw new Error('Error occurred while claiming. Please try again')
        }
      }

      const kytRes = await fetch('/api/screen-wallet', {
        method: 'POST',
      })

      if (!kytRes.ok) {
        const message = await kytRes.text()
        throw new Error(message)
      }

      const txs: BatchUserOperationCallData = []

      // Block delayed claims
      const now = Date.now()
      if (
        migrationStDrvBalance + migrationDrvBalance < DELAYED_CLAIM_BALANCE_THRESHOLD &&
        now < DELAYED_AIRDROP_CLAIM_DATE_MS
      ) {
        throw new Error(
          `Claims less than ${formatTokenBalance(
            DELAYED_CLAIM_BALANCE_THRESHOLD,
            TokenId.DRV
          )} will be available after ${formatDateTime(DELAYED_AIRDROP_CLAIM_DATE_MS)}`
        )
      }

      const stDrvAmount = isStake
        ? amount > migrationStDrvBalance
          ? migrationStDrvBalance
          : amount
        : BigInt(0)
      const _drvAmount = isStake
        ? amount > migrationStDrvBalance
          ? // Claim outstanding stDRV amount in DRV
            amount - migrationStDrvBalance
          : BigInt(0)
        : amount
      const drvAmount = _drvAmount > migrationDrvBalance ? migrationDrvBalance : _drvAmount
      const cappedClaimAmount = drvAmount + stDrvAmount

      const tx = await getMigrationClaimFromScwTx(user.ownerAddress, {
        drvClaimAmount: drvAmount,
        stDrvClaimAmount: stDrvAmount,
      })
      txs.push(tx)

      if (isStake && drvAmount > BigInt(0)) {
        const allowance = await lyraClient.readContract({
          abi: erc20Abi,
          address: getLyraTokenAddress(TokenId.DRV),
          functionName: 'allowance',
          args: [user.address, getLyraTokenAddress(TokenId.STDRV)],
        })

        if (allowance < amount) {
          txs.push(getStakeApprovalTx())
        }

        txs.push(getStakeTx(drvAmount))
      }

      if (delegates) {
        txs.push(getDelegateTx(delegates))
        console.debug('Claim and delegate', { txs })
      }

      const claimAmountText = isStake
        ? formatTokenBalance(cappedClaimAmount, TokenId.STDRV)
        : formatTokenBalance(cappedClaimAmount, TokenId.DRV)

      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: `Confirm Claim ${claimAmountText}`,
          },
          inProgressContext: {
            title: `Claiming ${claimAmountText}`,
          },
        }
      )

      console.debug('Claim migration tx hash', { txHash })

      options.onTransactionStatusChange('complete', {
        txHash,
        chain: lyraChain,
        title: `Successfully Claimed ${claimAmountText}`,
      })

      return txHash
    },
    [_sendUserOperation, migrationDrvBalance, migrationStDrvBalance, mutateAuth, user, walletClient]
  )

  const claimAirdrop = useCallback(
    async (claimOptions: ClaimAirdropOptions, options: TransactionOptions) => {
      if (!walletClient || !user) {
        throw new Error('Wallet not ready')
      }
      if (!user.acknowledgedAirdropTerms) {
        await fetch('/api/acknowledge-terms/airdrop', { method: 'POST' })

        const newAuth = await mutateAuth()
        if (!newAuth?.user?.acknowledgedAirdropTerms) {
          throw new Error('Error occurred while claiming. Please try again')
        }
      }

      const kytRes = await fetch('/api/screen-wallet', {
        method: 'POST',
      })

      if (!kytRes.ok) {
        const message = await kytRes.text()
        throw new Error(message)
      }

      const { isInstantUnstake } = claimOptions

      // Claim
      const txs: BatchUserOperationCallData = []
      const { tx, amount } = await getClaimAirdropTx(user.address, claimOptions)

      // Block delayed claims
      const now = Date.now()
      if (amount < DELAYED_CLAIM_BALANCE_THRESHOLD && now < DELAYED_AIRDROP_CLAIM_DATE_MS) {
        throw new Error(
          `Claims less than ${formatTokenBalance(
            DELAYED_CLAIM_BALANCE_THRESHOLD,
            TokenId.DRV
          )} will be available after ${formatDateTime(DELAYED_AIRDROP_CLAIM_DATE_MS)}`
        )
      }

      txs.push(tx)
      // Unstake or delegate
      if (claimOptions.delegates) {
        txs.push(getDelegateTx(claimOptions.delegates))
        console.debug('Claim and delegate', { txs })
      } else if (isInstantUnstake) {
        txs.push(getUnstakeTx(amount, { isInstant: isInstantUnstake }))
        console.debug('Claim and unstake', { txs })
      }

      const claimAmountText = isInstantUnstake
        ? formatTokenBalance(getUnstakePenaltyAmount(amount), TokenId.DRV)
        : formatTokenBalance(amount, TokenId.STDRV)

      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: `Confirm Claim ${claimAmountText}`,
          },
          inProgressContext: {
            title: `Claiming ${claimAmountText}`,
          },
        }
      )

      console.debug('Claim tx', { txHash })

      options.onTransactionStatusChange('complete', {
        txHash,
        chain: lyraChain,
        title: `Successfully Claimed ${claimAmountText}`,
      })
      return txHash
    },
    [_sendUserOperation, mutateAuth, user, walletClient]
  )

  const claimRewards = useCallback(
    async (batchIds: string[], options: TransactionOptions) => {
      if (!walletClient || !user) {
        throw new Error('Wallet not ready')
      }

      console.debug('claiming', batchIds)
      const txs = await getClaimRewardsTxs(batchIds)

      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: 'Claim Rewards',
          },
          inProgressContext: {
            title: 'Claiming Rewards',
          },
        }
      )

      console.debug('Claim tx', { txHash })

      options.onTransactionStatusChange('complete', {
        txHash,
        chain: lyraChain,
        title: 'Claimed Rewards',
      })
      return txHash
    },
    [_sendUserOperation, user, walletClient]
  )

  /**
   * Mint testnet tokens into smart contract wallet
   */
  const mintTestnetUsdc = useCallback(async () => {
    if (!isTestnet) {
      throw new Error('Can only mint USDC on testnet')
    }
    console.debug('minting testnet usdc')
    await fetch('/api/mint', { method: 'POST' })

    let usdcBalance = BigInt(0)
    let remAttempts = 20 // poll 10 seconds
    while (usdcBalance === BigInt(0) && remAttempts >= 0) {
      await sleep(500)
      const newBalances = await mutateBalances()
      usdcBalance = newBalances.balances.USDC
      console.debug('polling usdc balance', usdcBalance)
      remAttempts -= 1
    }

    if (usdcBalance === BigInt(0)) {
      throw new Error('Failed to mint USDC on testnet')
    }

    return usdcBalance
  }, [mutateBalances])

  /**
   * POLLING
   */

  // Poll balances
  useEffect(() => {
    const interval = setInterval(mutateBalances, BALANCE_POLLING_INTERVAL_MS)
    mutateBalances()
    return () => {
      clearInterval(interval)
    }
  }, [mutateBalances, user?.address])

  // Poll pending bridges
  useEffect(() => {
    // Initial fetch when wallet changes
    mutateAndUpdatePendingBridges()

    let interval: NodeJS.Timeout
    if (pendingBridges.length) {
      // Only poll when there are pending bridges in progress (triggered by bridgeTo/FromLyraChain)
      console.debug('polling bridges', pendingBridges.length)
      interval = setInterval(mutateAndUpdatePendingBridges, PENDING_BRIDGES_POLLING_INTERVAL_MS)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [mutateAndUpdatePendingBridges, pendingBridges.length, user?.address])

  // Whenever pending bridges change, update balances
  useEffect(() => {
    mutateBalances()
  }, [mutateBalances, pendingBridges.length])

  const value = useMemo(() => {
    return {
      address,
      ownerAddress,
      disabledReason,
      pendingBridges,
      balances,
      depositBalances,
      sessionKey,
      tryEnableTradingAccount,
      registerSessionKey,
      revokeSessionKey,
      editSessionKeyLabel,
      bridgeToLyraChain,
      bridgeFromLyraChain,
      mintYieldToken,
      redeemYieldToken,
      mintTestnetUsdc,
      mutateBalances,
      mutateAndUpdatePendingBridges,
      createAndDepositFirstSubaccount,
      stake,
      unstake,
      cancelUnstake,
      finalizeUnstake,
      claimAirdrop,
      claimMigration,
      claimRewards,
    }
  }, [
    address,
    ownerAddress,
    disabledReason,
    pendingBridges,
    balances,
    depositBalances,
    sessionKey,
    tryEnableTradingAccount,
    registerSessionKey,
    revokeSessionKey,
    editSessionKeyLabel,
    bridgeToLyraChain,
    bridgeFromLyraChain,
    mintYieldToken,
    redeemYieldToken,
    mintTestnetUsdc,
    mutateBalances,
    mutateAndUpdatePendingBridges,
    createAndDepositFirstSubaccount,
    stake,
    unstake,
    cancelUnstake,
    finalizeUnstake,
    claimAirdrop,
    claimMigration,
    claimRewards,
  ])

  return <TransactionContext.Provider value={value}>{children}</TransactionContext.Provider>
}
