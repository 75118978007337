import { REWARDS_ROUND_CONFIGS } from '../constants/rewards'
import { baseUrl } from '../constants/urls'

export const getRewardsRoundForTimestamp = (now: Date) => {
  return (
    REWARDS_ROUND_CONFIGS.find(
      (epoch) => epoch.endTimestamp >= now.getTime() && epoch.startTimestamp <= now.getTime()
    ) ?? REWARDS_ROUND_CONFIGS[REWARDS_ROUND_CONFIGS.length - 1] // get latest
  )
}

export const getPrevRewardsRoundForTimestamp = (now: Date) => {
  const currRound = getRewardsRoundForTimestamp(now)
  const prevRoundId = Math.max(currRound.roundId - 1, 1)
  const prevRound = getRewardsRound(prevRoundId)
  return prevRound
}

export const getRewardsRound = (targetRoundId: number) => {
  let left = 0,
    right = REWARDS_ROUND_CONFIGS[REWARDS_ROUND_CONFIGS.length - 1].roundId
  let closestGreater = REWARDS_ROUND_CONFIGS[right]

  while (left <= right) {
    const mid = Math.floor((left + right) / 2)

    if (REWARDS_ROUND_CONFIGS[mid].roundId === targetRoundId) {
      return REWARDS_ROUND_CONFIGS[mid]
    } else if (REWARDS_ROUND_CONFIGS[mid].roundId > targetRoundId) {
      closestGreater = REWARDS_ROUND_CONFIGS[mid]
      right = mid - 1 // Search in the left half
    } else {
      left = mid + 1 // Search in the right half
    }
  }

  return closestGreater
}

export const getReferralLinks = (
  referralCode: string
): {
  inviteLink: string
  twitterShareLink: string
} => {
  const inviteLink = `${baseUrl}/invite/${referralCode}`
  return {
    inviteLink,
    twitterShareLink: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `Earn DRV, OP and USDC when you trade on Derive: ${inviteLink}`
    )}`,
  }
}
