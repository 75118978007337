import { BatchUserOperationCallData } from '@alchemy/aa-core'
import { MAX_INT, WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { Address, encodeFunctionData, getAddress } from 'viem'

import delegateErc20Abi from '../abis/delegateErc20Abi'
import erc20Abi from '../abis/erc20Abi'
import rewardsDistributorAbi from '../abis/rewardsDistributorAbi'
import claimAbi from '../abis/tgeClaimAbi'
import { DepositNetwork } from '../constants/chains'
import { lyraClient } from '../constants/client'
import { lyraContractAddresses } from '../constants/contracts'
import { ClaimAirdropOptions } from '../constants/drv'
import { isTestnet } from '../constants/env'
import { ClaimableRewards, RewardsCategory } from '../constants/rewards'
import { TokenId } from '../constants/tokens'
import { estimateBlockNumberAtDate } from './block'
import { getRewardsRoundForTimestamp } from './rewards'
import { getNetworkClient } from './rpc'
import { getLyraTokenAddress, getLyraTokenForAddress, getTokenDecimals } from './tokens'

// Vest for 0 days - 20% penalty
const INSTANT_UNSTAKE_VEST_DURATION_SEC = 0
// Max vest (28 days) - no penalty
const UNSTAKE_VEST_DURATION_SEC = 28 * SECONDS_IN_DAY

const TOTAL_DELEGATE_SHARE = 10_000

// 20% haircut
export const getUnstakePenaltyAmount = (amount: bigint) => {
  return (amount * BigInt(4)) / BigInt(5)
}

export const getPreUnstakePenaltyAmount = (amount: bigint) => {
  return (amount * BigInt(5)) / BigInt(4)
}

export const getMigrationClaimFromScwTx = (
  ownerAddress: Address,
  {
    drvClaimAmount = BigInt(0),
    stDrvClaimAmount = BigInt(0),
  }: {
    stDrvClaimAmount?: bigint
    drvClaimAmount?: bigint
  }
): BatchUserOperationCallData[0] => {
  console.debug('getMigrationClaimFromScwTx', {
    drvClaimAmount,
    stDrvClaimAmount,
  })
  return {
    target: lyraContractAddresses.migrationClaim,
    data: encodeFunctionData({
      abi: claimAbi,
      functionName: 'claimFromSCW',
      args: [ownerAddress, drvClaimAmount, stDrvClaimAmount],
    }),
  }
}

export const getDistributorClaimAllTx = (): BatchUserOperationCallData[0] => {
  return {
    target: lyraContractAddresses.rewardsDistributor,
    data: encodeFunctionData({
      abi: rewardsDistributorAbi,
      functionName: 'claimAll',
      args: [],
    }),
  }
}

export const getDistributorClaimTx = (batchId: bigint): BatchUserOperationCallData[0] => {
  return {
    target: lyraContractAddresses.rewardsDistributor,
    data: encodeFunctionData({
      abi: rewardsDistributorAbi,
      functionName: 'claim',
      args: [batchId],
    }),
  }
}

export const getClaimAirdropTx = async (
  address: Address,
  options: ClaimAirdropOptions
): Promise<{ tx: BatchUserOperationCallData[0]; amount: bigint }> => {
  console.debug('getClaimAirdropTx', {
    options,
  })

  const claimsByToken = await lyraClient.readContract({
    abi: rewardsDistributorAbi,
    address: lyraContractAddresses.rewardsDistributor,
    functionName: 'getAvailableClaimsByToken',
    args: [address],
  })
  const claimableStDrvBalance =
    claimsByToken.find(
      (claim) => claim.token.toLowerCase() === getLyraTokenAddress(TokenId.STDRV).toLowerCase()
    )?.amount ?? BigInt(0)

  return {
    tx: getDistributorClaimAllTx(),
    amount: claimableStDrvBalance,
  }
}

const getRewardsCategoryForBatchTag = (tag: string): RewardsCategory | null => {
  if (tag.includes('trading')) {
    return 'trading'
  } else if (tag.includes('referral')) {
    return 'referral'
  } else if (tag.includes('staking')) {
    return 'staking'
  } else if (tag.includes('aero')) {
    return 'aero'
  } else if (tag.includes('airdrop')) {
    return 'airdrop'
  } else {
    return null
  }
}

export const fetchClaimableRewards = async (
  address: Address,
  selectedCategory?: RewardsCategory,
  ignoreTags?: string[]
): Promise<ClaimableRewards> => {
  const allBatches = await lyraClient.readContract({
    abi: rewardsDistributorAbi,
    address: lyraContractAddresses.rewardsDistributor,
    functionName: 'getAllUserBatchInfo',
    args: [address],
  })

  const claimableRewardBatches = allBatches.filter((batch) => batch.enabled)

  const claimableRewards: ClaimableRewards = {
    trading: {},
    referral: {},
    staking: {},
    aero: {},
    airdrop: {},
  }

  claimableRewardBatches.forEach((batch) => {
    const token = getLyraTokenForAddress(batch.token)
    const tag = batch.tag
    const category = getRewardsCategoryForBatchTag(tag)
    if (!category) {
      console.warn(`skipped claimable reward with unrecognized tag ${tag}`)
      return
    }

    if (selectedCategory && category !== selectedCategory) {
      return
    }

    if (ignoreTags?.includes(tag)) {
      return
    }

    if (!claimableRewards[category][token]) {
      // initialize
      claimableRewards[category][token] = {
        amount: 0,
        batchIds: [],
        batchTags: [],
      }
    }

    const amountNum = bigNumberToNumberUNSAFE(batch.amount, getTokenDecimals(token))
    claimableRewards[category][token].amount += amountNum
    claimableRewards[category][token].batchIds.push(batch.batchId.toString())
    claimableRewards[category][token].batchTags.push(batch.tag.toString())
  })

  return claimableRewards
}

export const getClaimRewardsTxs = async (
  batchIds: string[]
): Promise<BatchUserOperationCallData> => {
  return batchIds.map((batchId) => getDistributorClaimTx(BigInt(batchId)))
}

export const getUnstakeTx = (
  amount: bigint,
  { isInstant }: { isInstant: boolean }
): BatchUserOperationCallData[0] => {
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'redeem',
      args: [
        amount,
        isInstant ? BigInt(INSTANT_UNSTAKE_VEST_DURATION_SEC) : BigInt(UNSTAKE_VEST_DURATION_SEC),
      ],
    }),
  }
}

export const getCancelUnstakeTx = (redeemIndex: number): BatchUserOperationCallData[0] => {
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'cancelRedeem',
      args: [BigInt(redeemIndex)],
    }),
  }
}

export const getFinalizeUnstakeTx = (redeemIndex: number): BatchUserOperationCallData[0] => {
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'finalizeRedeem',
      args: [BigInt(redeemIndex)],
    }),
  }
}

export const getStakeApprovalTx = () => {
  return {
    target: getLyraTokenAddress(TokenId.DRV),
    data: encodeFunctionData({
      abi: erc20Abi,
      functionName: 'approve',
      args: [getLyraTokenAddress(TokenId.STDRV), MAX_INT],
    }),
  }
}

export const getStakeTx = (amount: bigint): BatchUserOperationCallData[0] => {
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'convert',
      args: [amount],
    }),
  }
}

// !!IMPORTANT delegation is to EOA _not_ SCW
export const getDelegateTx = (delegates: Address[]): BatchUserOperationCallData[0] => {
  const delegateSet = new Set(delegates)
  const sortedDelegates = Array.from(delegateSet).sort((a, b) => parseInt(a, 16) - parseInt(b, 16))
  const delegatesArg = sortedDelegates.map((delegate) => ({
    _delegatee: delegate,
    _numerator: BigInt(Math.floor(TOTAL_DELEGATE_SHARE / delegates.length)),
  }))
  return {
    target: getLyraTokenAddress(TokenId.STDRV),
    data: encodeFunctionData({
      abi: delegateErc20Abi,
      functionName: 'delegate',
      args: [delegatesArg],
    }),
  }
}

export const fetchDrvCirculatingSupply = async () => {
  if (isTestnet) {
    // use totalSupply as circulatingSupply on testnet
    const drvAddress = getLyraTokenAddress(TokenId.DRV)
    return bigNumberToNumberUNSAFE(
      await lyraClient.readContract({
        abi: erc20Abi,
        address: drvAddress,
        functionName: 'totalSupply',
        args: [],
      }),
      WEI_DECIMALS
    )
  }

  // DAO and foundation holdings (addresses we subtract)
  const DAO_HOLDINGS: Record<string, Address[]> = {
    derive: [getAddress('0xB176A44D819372A38cee878fB0603AEd4d26C5a5')],
    ethereum: [
      getAddress('0x246d38588b16Dd877c558b245e6D5a711C649fCF'),
      getAddress('0xAC81065eC33C149De27ab471181bF1baE198b5EA'),
    ],
    base: [getAddress('0xbfA8B86391c5eCAd0eBe2B158D9Cd9866DDBAaDa')],
    optimism: [getAddress('0xD4C00FE7657791C2A43025dE483F05E49A5f76A6')],
    arbitrum: [getAddress('0x2CcF21e5912e9ecCcB0ecdEe9744E5c507cf88AE')],
  }

  // Official DRV token addresses
  const DRV_TOKENS: Record<string, Address> = {
    ethereum: getAddress('0xB1D1eae60EEA9525032a6DCb4c1CE336a1dE71BE'),
    base: getAddress('0x9d0E8f5b25384C7310CB8C6aE32C8fbeb645d083'),
    optimism: getAddress('0x33800De7E817A70A694F31476313A7c572BBa100'),
    arbitrum: getAddress('0x77b7787a09818502305C95d68A2571F090abb135'),
    derive: getAddress('0x2EE0fd70756EDC663AcC9676658A1497C247693A'),
  }

  const TOTAL_SUPPLY = BigInt(1_000_000_000) // 1 billion DRV tokens

  // Normalize balances by dividing by 10^18
  const normalizeBalance = (balance: bigint): bigint => {
    return balance / BigInt(10 ** 18)
  }

  // Fetch token balances for a specific chain
  const fetchBalancesForChain = async (
    network: string,
    tokenAddress: Address,
    accountAddresses: Address[]
  ): Promise<bigint> => {
    const client =
      network === 'derive' ? lyraClient : await getNetworkClient(network as DepositNetwork)
    const balances = await Promise.all(
      accountAddresses.map(async (account) => {
        return client.readContract({
          address: tokenAddress,
          abi: delegateErc20Abi,
          functionName: 'balanceOf',
          args: [account],
        })
      })
    )

    // Normalize the total balance
    return balances.reduce((total, balance) => total + normalizeBalance(BigInt(balance)), BigInt(0))
  }

  // Fetch DAO balances for all networks
  const daoBalances = await Promise.all(
    Object.entries(DAO_HOLDINGS).map(async ([network, addresses]) => {
      const tokenAddress = DRV_TOKENS[network]
      if (!tokenAddress) throw new Error(`Missing token address for network: ${network}`)
      return fetchBalancesForChain(network, tokenAddress, addresses)
    })
  )

  // Total DAO balance and circulating supply
  const totalDaoBalance = daoBalances.reduce((total, balance) => total + balance, BigInt(0))
  const circulatingSupply = TOTAL_SUPPLY - totalDaoBalance
  return bigNumberToNumberUNSAFE(circulatingSupply, 0)
}

export const fetchDrvStakingApy = async (forDate?: Date) => {
  const stDrvAddress = getLyraTokenAddress(TokenId.STDRV)

  const blockNumber = forDate ? BigInt(await estimateBlockNumberAtDate(forDate)) : undefined
  const [totalStakedWithUnstakeAmountBn, pendingUnstakeAmountBn] = await Promise.all([
    lyraClient.readContract({
      abi: erc20Abi,
      address: stDrvAddress,
      functionName: 'totalSupply',
      args: [],
      blockNumber,
    }),
    lyraClient.readContract({
      abi: erc20Abi,
      address: stDrvAddress,
      functionName: 'balanceOf',
      args: [stDrvAddress],
      blockNumber,
    }),
  ])

  const totalStakedBn = totalStakedWithUnstakeAmountBn - pendingUnstakeAmountBn
  const totalStaked = bigNumberToNumberUNSAFE(totalStakedBn, WEI_DECIMALS)

  const round = getRewardsRoundForTimestamp(forDate ? forDate : new Date())

  const stakingApy = Object.entries(round.totalStakingRewards).reduce(
    (dict, [key, totalRewards]) => {
      const tokenId = key as TokenId
      return {
        ...dict,
        [tokenId]: totalStaked > 0 ? (totalRewards * 52) / totalStaked : 0,
      }
    },
    {} as Partial<Record<TokenId, number>>
  )

  return {
    stakingApy,
    totalStaked,
  }
}

export const fetchDrvTotalSupply = async (): Promise<number> => {
  const drvAddress = getLyraTokenAddress(TokenId.DRV)
  const totalSupplyBn = await lyraClient.readContract({
    abi: erc20Abi,
    address: drvAddress,
    functionName: 'totalSupply',
    args: [],
  })
  const totalSupply = bigNumberToNumberUNSAFE(totalSupplyBn, WEI_DECIMALS)
  return totalSupply
}
