import { SECONDS_IN_WEEK } from '@lyra/core/constants/time'
import { Address } from 'viem'

import { TokenId } from './tokens'
import { YieldTokenId } from './yield'

export type RewardsCategory = 'trading' | 'referral' | 'staking' | 'aero' | 'airdrop'

export type RewardsRoundReferrer = {
  address: Address
  referredFees: number
  feeShare: number // in case we want to increase for affiliates later
  rewards: Partial<Record<TokenId, number>>
  referrals: Record<
    Address,
    number // fees
  > // map of address to fee points
}

export type RewardsRoundTradingUser = {
  address: Address
  roundId: number
  volume: number
  fees: number
  boostedFees: number // with boosts applied
  boostedFeeShare: number
  rewards: Partial<Record<TokenId, number>>
  // ranks
  volumeRank: number
  feesRank: number
  boostedFeesRank: number
}

export type RewardsRoundLeaderboardUser = RewardsRoundTradingUser & {
  username?: string
  referredBy?: {
    username?: string
    address: Address
    code: string
  }
}

export type RewardsRoundTradingStats = {
  roundId: number
  totalFees: number
  totalBoostedFees: number
  totalVolume: number
  totalUsers: number
  totalRewards: Partial<Record<TokenId, number>>
  lastUpdatedTimestamp: number
}

export type RewardsRoundReferralStats = {
  roundId: number
  totalReferrers: number
  totalReferredFees: number
  totalRewards: Partial<Record<TokenId, number>> // always usdc, but give flexibility for other tokens
  lastUpdatedTimestamp: number
}

export type RewardsRoundConfig = {
  roundId: number // maps to index+1 of the current round
  startTimestamp: number
  endTimestamp: number
  // trading rewards
  totalTradingRewards: Partial<
    Record<
      TokenId,
      {
        total: number
        minPerUser: number
      }
    >
  >
  // referral rewards
  referralFeeShare: number
  // staking rewards
  totalStakingRewards: Partial<Record<TokenId, number>>
}

export type RewardsRoundAllVaultsShareSnapshot = Record<
  YieldTokenId,
  RewardsRoundVaultShareSnapshot
>

type UserVaultShare = {
  vaultShare: number
  balance: number
  timestamp: number
  scwAddress: Address
}

export type RewardsRoundVaultShareSnapshot = Record<Address, UserVaultShare>

export type UserVaultsShareSnapshot = Record<YieldTokenId, UserVaultShare>

export type ClaimableRewards = Record<
  RewardsCategory,
  Partial<Record<TokenId, { amount: number; batchIds: string[]; batchTags: string[] }>>
>

export type RewardsTradingResponse = {
  isBlocked: boolean
  estNewRewards: Partial<Record<TokenId, number>> | null | undefined
  claimableRewards: ClaimableRewards['trading'] | null | undefined
  tradingUser: RewardsRoundTradingUser | null | undefined
  tradingStats: RewardsRoundTradingStats | null | undefined
  prevRound: RewardsRoundConfig
  currRound: RewardsRoundConfig
}

export type RewardsReferralResponse = {
  isBlocked: boolean
  estNewRewards: Partial<Record<TokenId, number>> | null | undefined
  claimableRewards: ClaimableRewards['referral'] | null | undefined
  referrer: RewardsRoundReferrer | null | undefined
  referralStats: RewardsRoundReferralStats | null | undefined
  prevRound: RewardsRoundConfig
  currRound: RewardsRoundConfig
}

export type RewardsStakingResponse = {
  claimableRewards: ClaimableRewards['staking'] | null | undefined
  totalSupply: number
  circulatingSupply: number
  stakingApy: Partial<Record<TokenId, number>>
  totalStaked: number
  prevRound: RewardsRoundConfig
  currRound: RewardsRoundConfig
}

export type RewardsAerodromeResponse = {
  estNewDrvRewards: number | null | undefined
  claimableDrvRewards: ClaimableRewards['aero']['STDRV'] | null | undefined
}

export const REWARDS_ROUND_CONFIGS: RewardsRoundConfig[] = [
  {
    roundId: 1,
    // Jan 13 00:00 UTC
    startTimestamp: 1736726400000,
    // Jan 22 00:00 UTC
    endTimestamp: 1737504000000,
    totalTradingRewards: {
      [TokenId.STDRV]: {
        total: 1_000_000,
        minPerUser: 10,
      },
      [TokenId.OP]: {
        total: 20_000,
        minPerUser: 1,
      },
    },
    referralFeeShare: 0.1,
    totalStakingRewards: {},
  },
  {
    roundId: 2,
    // Jan 22 00:00 UTC
    startTimestamp: 1737504000000,
    endTimestamp: 1737504000000 + SECONDS_IN_WEEK * 1000,
    totalTradingRewards: {
      [TokenId.STDRV]: {
        total: 1_000_000,
        minPerUser: 10,
      },
      [TokenId.OP]: {
        total: 10_000,
        minPerUser: 1,
      },
    },
    referralFeeShare: 0.1,
    totalStakingRewards: {
      [TokenId.STDRV]: 250_000,
    },
  },
  {
    roundId: 3,
    // Jan 29
    startTimestamp: 1738108800000,
    endTimestamp: 1738108800000 + SECONDS_IN_WEEK * 1000,
    totalTradingRewards: {
      [TokenId.STDRV]: {
        total: 1_000_000,
        minPerUser: 10,
      },
      [TokenId.OP]: {
        total: 10_000,
        minPerUser: 1,
      },
    },
    referralFeeShare: 0.1,
    totalStakingRewards: {
      [TokenId.STDRV]: 250_000,
    },
  },
  {
    roundId: 4,
    // Feb 5
    startTimestamp: 1738108800000 + SECONDS_IN_WEEK * 1000,
    endTimestamp: 1738108800000 + 2 * SECONDS_IN_WEEK * 1000,
    totalTradingRewards: {
      [TokenId.STDRV]: {
        total: 1_000_000,
        minPerUser: 10,
      },
      [TokenId.OP]: {
        total: 10_000,
        minPerUser: 1,
      },
    },
    referralFeeShare: 0.1,
    totalStakingRewards: {
      [TokenId.STDRV]: 250_000,
    },
  },
  {
    roundId: 5,
    // Feb 12
    startTimestamp: 1738108800000 + 2 * SECONDS_IN_WEEK * 1000,
    endTimestamp: 1738108800000 + 3 * SECONDS_IN_WEEK * 1000,
    totalTradingRewards: {
      [TokenId.STDRV]: {
        total: 1_000_000,
        minPerUser: 10,
      },
      [TokenId.OP]: {
        total: 10_000,
        minPerUser: 1,
      },
    },
    referralFeeShare: 0.1,
    totalStakingRewards: {
      [TokenId.STDRV]: 250_000,
    },
  },
]

export const getEmptyRewardsRoundTradingStats = (
  config: RewardsRoundConfig
): RewardsRoundTradingStats => ({
  roundId: config.roundId,
  totalFees: 0,
  totalBoostedFees: 0,
  totalVolume: 0,
  totalUsers: 0,
  totalRewards: Object.keys(config).reduce((dict, token) => ({ ...dict, [token]: 0 }), {}),
  lastUpdatedTimestamp: Date.now(),
})

export const getEmptyRewardsRoundReferralStats = (roundId: number): RewardsRoundReferralStats => ({
  roundId,
  totalReferredFees: 0,
  totalReferrers: 0,
  totalRewards: {
    [TokenId.USDC]: 0,
  },
  lastUpdatedTimestamp: Date.now(),
})
